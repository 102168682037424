<template>
  <div class="popup_overlay px-4 relative ">
    <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;overflow: initial;">
      <div class="relative rounded-xl">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
              {{this.selectedItemId > 0 ? 'Edit Item' : 'Add Item'}}
            </div>
            <div v-if="selectedItemId > 0">
              <div class=" text-error heading-5 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click.stop="removeConfirm = true">
                <i class="fa-solid fa-trash"></i>
              </div>
            </div>
          </div>
          <div class="p-4">
            <div class="mb-4">
              <TextField
              :inputId="'itemName2'"
              :textMaxlength="50"
              :showcharLimit="true"
              :inputext="sendObj.checkListTitle"
              :placholderText="`CheckList Title`"
              :lableText="'CheckList Title'"
              :autoFocus="false"
              @keyPressAction="errObj.itemNameErr = ''"
              :fieldError="errObj.itemNameErr !== ''"
              @inputChangeAction="(data) => sendObj.checkListTitle = data"
              />
              <p v-if="errObj.itemNameErr !== ''" class="text-error heading-7">{{errObj.itemNameErr}}</p>
            </div>
            <div class="mb-4">
              <div class="">
                <Dropdown
                :inputext="sendObj.categoryName"
                :inputId="'categorySel'"
                :lableText="'Category'"
                :placholderText="`Select Category`"
                :autoFocus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="true"
                :showPlus="false"
                :showClearIcon="true"
                :showClearIconNear="true"
                :keyName="'categoryName'"
                :listId="'checkListCategoryMasterId'"
                :items="categoryList"
                @inputChangeAction="callsearchCust"
                @selectNewForVal="selectCatValue"
                />
              </div>
            </div>
            <div class="">
              <div class="flex items-center">
                <label class="cu_switch cursor-pointer mr-3" :class="!sendObj.isActive ? 'ml-2' : ''">
                  <input type="checkbox" v-model="sendObj.isActive">
                  <span class="cu_slider cu_round"></span>
                </label>
                <p class="text-gray3 pl-1  cursor-pointer" @click="sendObj.isActive=!sendObj.isActive">{{sendObj.isActive ? 'Active' : 'InActive'}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
          <div class="text-rigth flex gap-2 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="createNewItem()"/>
          </div>
          </div>
      </div>
    </div>
    <ConfirmBox v-if="removeConfirm" :message="'Once delete cannot undone.'" :title="'Are you sure?'" />
  </div>
</template>
<script>
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
import Dropdown from '@/View/components/dropdown.vue'
import TextField from '@/View/components/textfield.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    ConfirmBox,
    Button,
    TextField,
    Dropdown,
  },
  mixins: [deboucneMixin],
  props: ['selectedItemId'],
  data() {
    return {
      removeConfirm: false,
      sendObj: {
        checkListItemMasterId: 0,
        checkListCategoryMasterId: 0,
        checkListTitle: '',
        isActive: true,
        categoryName: '',
        visitDetailId: 0,
      },
      categoryList: [],
      searchApitext: '',
      getItemListing: () => { },
      errObj: {
        itemNameErr: '',
      }
    };
  },
  created() {
  },
  computed: {
  },
  watch: {
    sendObj: {
      handler () {
        if (this.sendObj.checkListTitle !== '') {
          this.errObj.itemNameErr = ''
        }
      },
      deep: true
    }
  },
  mounted() {
    this.$root.$on('confirmBoxHandler', (response) => {
    if (response) {
      this.deleteApi()
    }
    document.body.style = 'overflow: visible;'
    this.removeConfirm = false
  })
    if (this.selectedItemId > 0) {
      this.getItemSDetail()
    }
    this.getItemListing = this.debounce(function () {
      this.getCatogeryList()
    }, 500)
    this.getCatogeryList()
  },
  methods: {
    createNewItem() {
      let isValid = true
      if (this.sendObj.checkListTitle === '') {
        isValid = false
        this.errObj.itemNameErr = 'CheckList Item Name is Required'
      }
      if (isValid) {
        if (this.selectedItemId === 0) {
          this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
          MyJobApp.SettingCheckListItemAdd(
            this.sendObj,
            response => {
              this.$root.$emit('closeaddEditItems', true)
              this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
              this.$store.dispatch('SetLoader', { status: false, message: '' })
            },
            (error) => {
              this.$store.dispatch('SetAlert', { showAlert: true, message: error.message, color: 'error' })
              this.$store.dispatch('SetLoader', { status: false, message: '' })
            }
          )
        } else if (this.selectedItemId > 0) {
          this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
          MyJobApp.SettingCheckListItemUpdate(
            this.sendObj,
            response => {
              this.$root.$emit('closeaddEditItems', true)
              this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
              this.$store.dispatch('SetLoader', { status: false, message: '' })
            },
            (error) => {
              this.$store.dispatch('SetAlert', { showAlert: true, message: error.message, color: 'error' })
              this.$store.dispatch('SetLoader', { status: false, message: '' })
            }
          )
        }
      }
    },
    selectCatValue(data) {
      if (data !== null) {
        this.sendObj.checkListCategoryMasterId = data.checkListCategoryMasterId
        this.sendObj.categoryName = data.categoryName
      } else {
        this.sendObj.checkListCategoryMasterId = 0
        this.sendObj.categoryName = ''
      }
    },
    discard() {
      this.$root.$emit('closeaddEditItems')
    },
    callsearchCust(text) {
      this.searchApitext = text
      this.getItemListing()
    },
    getItemSDetail() {
      console.log("call", this.$route);
      this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
      MyJobApp.SettingCheckListItemDetail(
        this.selectedItemId,
        (response) => {
          this.sendObj.checkListItemMasterId = response.Data.checkListItemMasterId
          this.sendObj.checkListCategoryMasterId = response.Data.checkListCategoryMasterId
          this.sendObj.checkListTitle = response.Data.checkListTitle
          this.sendObj.isActive = response.Data.isActive
          this.sendObj.categoryName = response.Data.categoryName
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    deleteApi () {
      MyJobApp.SettingCheckListItemDelete(
        this.selectedItemId,
        response => {
          console.log('response', response);
          this.$root.$emit('closeaddEditItems', true)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getCatogeryList() {
      MyJobApp.SettingCheckListCategoryList(
        0,
        20,
        this.searchApitext,
        response => {
          this.categoryList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.$store.dispatch('SetLoader', { status: false, message: '' })
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', { status: false, message: '' })
        }
      )
    },
  },
  beforeDestroy() {
    this.$root.$off('confirmBoxHandler')
  },
};
</script>
<style scoped></style>