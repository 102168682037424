<template>
  <div class="popup_overlay relative px-4">
       <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
          <div  class="mb-4 font-bold">
            <p class="heading-3 text-text1 whitespace-pre-line">{{title}}</p>
          </div>
          <div  class="mb-5 font-bold">
            <p class="heading-6 text-text1 mb-4">{{message}}</p>
            <div v-if="yesConfir">
              <TextField
              :inputId="'yesinputText'"
              :showcharLimit="false"
              :inputext="yesinputText"
              :lableText="`${inputLable}`"
              :autoFocus="true"
              @inputChangeAction="(data) => yesinputText = data"  />
            </div>
          </div>
          <div v-if="warning" class="flex gap-2 justify-end">
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'OK'" @buttonAction="cancel"/>
          </div>
          <div v-else class="flex gap-2 justify-end">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="`${lbtnColor ? lbtnColor : 'gray1' }`" :btnText="`${cancelbtnText ? cancelbtnText : 'No' }`" @buttonAction="cancel"/>
            <Button :disabled="yesinputText.toLowerCase() !== 'yes'" :btnSize="'medium'" :textColor="'white'" :btnColor="`${rbtnColor ? rbtnColor : 'error' }`" :btnText="`${confirmBtnText ? confirmBtnText : 'Yes' }`" @buttonAction="sure"/>
          </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
export default {
  props: ['title', 'message', 'warning', 'yesConfir', 'inputLable', 'cancelbtnText', 'confirmBtnText', "lbtnColor", "rbtnColor"],
  components: {
    Button,
    TextField,
    },
  data () {
    return {
      yesinputText: 'yes'
    }
  },
  computed: {
  },
  mounted () {
    if (this.yesConfir) {
      this.yesinputText = ''
    }
    document.body.style = 'overflow: hidden;'
  },
  methods: {
    cancel () {
      this.$root.$emit('confirmBoxHandler', false)
      this.$emit('confirmBoxHandler', false)
      // document.body.style = 'overflow: visible;'
    },
    sure () {
     this.$root.$emit('confirmBoxHandler', true)
     this.$emit('confirmBoxHandler', true)
    //  document.body.style = 'overflow: visible;'
    }
  }
}
</script>
