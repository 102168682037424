<template>
  <div class="mt-8">
    <div class="card bg-white rounded-xl p-2">
      <div class="">
        <div class="flex justify-between flex-wrap">
          <div class="flex flex-wrap mb-4">
            <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactive(true)" :selected="filterObject.isActive" :btnText="'Active'" />     
            <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactive(false)" :selected="!filterObject.isActive" :btnText="'Inactive'" />     
          </div>
        </div>
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Item...`" />
          </div>
          <Button @buttonAction="addNewItems()" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'"
            :btnText="'Add Item'" />
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div v-if="leadList.length > 0">
            <div v-for="(data, index) in leadList" :key="index"  class="cust_card m-1  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openDetail(data.checkListItemMasterId)">
              <div class="">
                <p class="text-text1 font-semibold heading-4">{{data.checkListTitle}}</p>
                <p class="text-gray4 heading-5" v-if="data.categoryName !== ''">/{{data.categoryName}}</p>
                </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div>
          <Pagination :totalDataCount="totalLeadCount" :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getLeadDataFromPagination" />
        </div>
        </div>
      </div>
    </div>
    <addNewItem v-if="addNewItemPopup" :selectedItemId="selectedItemid" />
  </div>
</template>
<script>
import addNewItem from './addEditCheckListItem.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
export default {
  name: "lead",
  components: {
    addNewItem,
    Pagination,
    StatusBtn,
    NoRecords,
    SearchTextInput,
    Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      addNewItemPopup: false,
      titleObj: [
        { title: 'Item Name', icon: '', sortName: 'itemName' },
        { title: 'Category', icon: '', sortName: 'categoryName' },
        { title: 'Unit', icon: '', sortName: 'itemUnit' },
        { title: 'Price', icon: '', sortName: 'itemPrice' },
      ],
      statusList: [],
      leadList: [],
      searchForLead: '',
      getLeadListDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalLeadCount: 0,
      selectedItemid: 0,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        sortBy: '',
        statusList: []
      },
    };
  },
  created() { },
  mounted() {
    this.getLeadList()
    this.getLeadListDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("closeaddEditItems", (response) => {
      console.log(response);
      if (response) {
        this.getLeadList()
        this.addNewItemPopup = false
      }
      this.addNewItemPopup = false
    });
  },
  beforeDestroy() {
    this.$root.$off('closeaddEditItems')
  },
  methods: {
    openDetail (Id) {
      this.selectedItemid = Id
      this.addNewItemPopup= true
    },
    addNewItems () {
      this.selectedItemid = 0
      this.addNewItemPopup= true
    },
    toggleActiveAndInactive (index) {
      if (index) {
        this.filterObject.isActive = true
      } else {
        this.filterObject.isActive = false
      }
      this.getLeadList()
    },
    getLeadDataFromPagination(data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      this.getLeadList(true)
    },
    searchCall(data) {
      this.searchForLead = data
      this.getLeadListDebounce()
    },
    getLeadList(showlistLoader) {
      if (showlistLoader && this.searchForLead !== '') {
        this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
      }
      if (this.searchForLead === '') {
        this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
      }
      MyJobApp.SettingCheckListItemList(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForLead,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.isActive,
        0,
        response => {
          let tempList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.leadList = tempList
          this.totalLeadCount = response.Data.count
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', { status: false, message: '' })
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', { status: false, message: '' })
        }
      )
    }
  }
};
</script>
<style scoped>
.column_size {
  min-width: 300px !important;
  width: 300px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>